import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/volatility-targeting/"
          }}>{`Insights: Volatility Targeting`}</a></li>
      </ul>
    </Aside>
    <p><em parentName="p">{`TuringTrader.com`}</em>{` is all about `}<a parentName="p" {...{
        "href": "/help/tactical-asset-allocation/"
      }}>{`tactical asset allocation`}</a>{`. This investment style takes advantage of market-anomalies to improve profits and reduce risk. The most well-known market-anomalies include trend, momentum, and mean-reversion. In this post, we explain the rationale behind these strategy types.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ed3fb4159d13d2a0be68f8ebe6de73bc/e5bc7/tools-2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAgT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHDeVBYIgf/xAAZEAADAAMAAAAAAAAAAAAAAAAAAQIDERP/2gAIAQEAAQUChzjtZ26bGtHTZ1uD/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgIBBQAAAAAAAAAAAAAAAAERIRIQMTJxgf/aAAgBAQAGPwLeS1iu9MqfhxITo//EABoQAAMBAQEBAAAAAAAAAAAAAAABESExYYH/2gAIAQEAAT8heHTSQU17jql3hmZOQS1eu70fyZdP/9oADAMBAAIAAwAAABBwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB8QAAEEAQUBAAAAAAAAAAAAAAEAESExgUFRcZGx8f/aAAgBAQABPxAWJoAM2aVxv6BxlT5Fm1T+SAU8KBiM1+BEaKQFwGzr/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: trend, momentum, and mean-reversion",
            "title": "TuringTrader.com: trend, momentum, and mean-reversion",
            "src": "/static/ed3fb4159d13d2a0be68f8ebe6de73bc/e5166/tools-2.jpg",
            "srcSet": ["/static/ed3fb4159d13d2a0be68f8ebe6de73bc/f93b5/tools-2.jpg 300w", "/static/ed3fb4159d13d2a0be68f8ebe6de73bc/b4294/tools-2.jpg 600w", "/static/ed3fb4159d13d2a0be68f8ebe6de73bc/e5166/tools-2.jpg 1200w", "/static/ed3fb4159d13d2a0be68f8ebe6de73bc/d9c39/tools-2.jpg 1800w", "/static/ed3fb4159d13d2a0be68f8ebe6de73bc/df51d/tools-2.jpg 2400w", "/static/ed3fb4159d13d2a0be68f8ebe6de73bc/e5bc7/tools-2.jpg 5184w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Trend-Following`}</h2>
    <p>{`The idea behind market trends is simple: Stocks that have been rising (or falling) in the past tend to continue growing (or falling) in the future.`}</p>
    <p>{`There are many methods of determining market trends. Among the most widely used ones are trend filters based on moving averages. For example, we can consider an asset to be trending up when trading above its 200-day moving average.`}</p>
    <p>{`It is important to note that trend is binary. Either an asset is trending or not. Alternatively, we could also say that assets are either trending up or trending down. The resulting strategies typically also have binary behavior, switching from risk-on to risk-off allocations. An excellent example of a trend-following strategy is `}<a parentName="p" {...{
        "href": "/portfolios/keller-lethargic-asset-allocation/"
      }}>{`Keller's Lethargic Asset Allocation`}</a>{`. `}</p>
    <h2>{`Momentum`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`Related Portfolios`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/?tag=momentum"
          }}>{`Momentum Portfolios`}</a></li>
      </ul>
    </Aside>
    <p>{`While establishing trends is useful, it might be an over-simplistic model. The binary nature of trend discards the asset's rate of change, which is valuable information. By taking the rate of change into account, we progress from trend to momentum. Unlike trend, momentum is a number, which we can use to rank our assets.`}</p>
    <p>{`The idea behind momentum is that a past rate of change is indicative of future returns. In other words, we expect assets with higher momentum to perform better than those with lower momentum.`}</p>
    <p>{`There are many variations to this idea, and experts often combine momentum with trend. The resulting strategies typically aim to select a limited number of high-momentum assets from a larger universe. An excellent example of a momentum strategy is `}<em parentName="p">{`TuringTrader`}</em>{`'s `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-the-loose/"
      }}>{`Stocks on the Loose`}</a>{`.`}</p>
    <p>{`Most portfolios consider trend and momentum over relatively long periods of approximately 6 months to 1 year. The reason for this is volatility: It is challenging to detect short-term trends because volatility often masks the underlying trend. As a result, trend-following and momentum portfolios suffer from lag: it takes them a while to adapt to new situations. Therefore, this type of strategy often underperforms in phases of rapidly changing market conditions.`}</p>
    <ButtonPrimary text="Momentum Portfolios" to="/portfolios/?tag=momentum" mdxType="ButtonPrimary" />
    <h2>{`Mean-Reversion`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`Related Portfolios`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/?tag=mean-reversion"
          }}>{`Mean-Reversion Portfolios`}</a></li>
      </ul>
    </Aside>
    <p>{`Momentum strategies assume a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Monotonic_function"
      }}>{`monotonically increasing`}</a>{` function between past returns and future performance. Again, this is an over-simplification.`}</p>
    <p>{`Many markets are influenced by human behavior. One of the observations of `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/b/behavioralfinance.asp"
      }}>{`behavioral finance`}</a>{` is that humans often over-react to the news. As such, stocks tend to move more than justified by the nature of the moves. The market then corrects this over-reaction at a later time.`}</p>
    <p>{`The concept of mean-reversion aims to profit from these over-reactions. It starts with defining overbought and oversold conditions. In simple terms, oversold conditions result from pullbacks of otherwise upwards-trending assets. The idea of mean-reversion implies that asset prices will snap back to the mean of their recent trend.`}</p>
    <p>{`There are many mechanisms for determining overbought or oversold conditions. Most often, these are based on fast technical indicators, e.g., a 2-day `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/r/rsi.asp"
      }}>{`RSI`}</a>{`. The resulting strategies typically buy into these oversold conditions and hold the positions only for a few days until prices have normalized. A sophisticated example of a mean-reversion strategy is `}<em parentName="p">{`TuringTrader`}</em>{`'s `}<a parentName="p" {...{
        "href": "/portfolios/tt-mean-kitty/"
      }}>{`Mean Kitty`}</a>{`.`}</p>
    <p>{`Mean-reversion strategies are a bit like catching a falling knife. We rely on the assumption that the asset will snap back, but this pullback might also be the beginning of a trend reversal. Consequently, the success of a mean-reversion strategy depends on reliable trend detection and adequate risk management.`}</p>
    <ButtonPrimary text="Mean-Reversion Portfolios" to="/portfolios/?tag=mean-reversion" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      